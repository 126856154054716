<template>
  <div class="video-gallery">
    <div class="container">
      <h2>Видео</h2>
    </div>
    <VideosGalleryBlock :videos="videos" />
    <Carousel
      class="videos__list"
      :breakpoints="breakpoints"
      :settings="settings"
      v-if="videos && videos.length !== 0"
    >
      <Slide v-for="(item, index) in videos" :key="index">
        <Video :video="item" />
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import VideosGalleryBlock from "@/components/Blocks/VideosGalleryBlock";
import Video from "@/components/Blocks/Video";
import { Carousel, Slide } from "vue3-carousel";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";

export default {
  name: "VideoGallery",
  components: { Video, Carousel, Slide, VideosGalleryBlock },
  data() {
    return {
      settings: {
        snapAlign: "center",
        wrapAround: true,
        itemsToScroll: 1,
        itemsToShow: 1.35,
        autoplay: 5000,
      },
      breakpoints: {
        1921: {
          itemsToShow: 5.2,
        },
        1500: {
          itemsToShow: 3.7,
        },
        1400: {
          itemsToShow: 2.6,
        },
        959: {
          itemsToShow: 2.5,
        },
        767: {
          itemsToShow: 1.8,
        },
        481: {
          itemsToShow: 2.2,
        },
        321: {
          itemsToShow: 1.5,
        },
      },
      videos: [],
    };
  },
  beforeMount() {
    if (!this.$store.state.storage.storageVideoData.length) {
      createRequest(requestConfigs.GETInfoBlock, {
        queryPayload: {
          key_block: "video_message",
        },
      }).then((result) => {
        this.$store.commit("setVideoData", result.data);
        this.$store.commit("setThreeData", result.data.slice(0, 3));
        this.videos = this.$store.state.storage.storageVideoData;
      });
    } else this.videos = this.$store.state.storage.storageVideoData;
  },
};
</script>

<style lang="scss">
.video-gallery {
  margin-top: 60px;
  h2 {
    margin-bottom: 30px;
  }
  .video {
    height: 100%;
  }
  @include adaptive(tablet-big) {
    .videos__list {
      display: none;
    }
  }
}
</style>
