<template>
  <div class="videos gallery-block">
    <div class="videos__content">
      <div class="container">
        <Carousel
          class="gallery-block__container"
          :wrap-around="true"
          v-if="videos.length !== 0"
          v-show="!isMobile"
        >
          <Slide
            v-for="(item, index) in videos"
            :key="index"
            style="'minWidth: 100%'"
          >
            <div class="gallery-block__main">
              <iframe width="330" height="200" :src="item.url"> </iframe>
              <div class="gallery-block__text">
                <span> {{ item.title }}</span>
              </div>
            </div>
          </Slide>

          <template #addons>
            <Navigation />
          </template>
        </Carousel>
        <div class="gallery-block__container--mobile" v-show="isMobile">
          <Video v-for="(item, index) in videos" :key="index" :video="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import Video from "@/components/Blocks/Video";
export default {
  name: "VideosGalleryBlock",
  components: { Video, Carousel, Slide, Navigation },
  data() {
    return {
      width: window.innerWidth,
    };
  },
  props: {
    videos: Array,
  },
  computed: {
    isMobile() {
      return this.width < 959;
    },
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.updateWidth);
  },
};
</script>

<style lang="scss">
.gallery-block {
  margin-top: 0;
  &__container {
    position: relative;
    .carousel {
      &__viewport {
        overflow: hidden;
      }
      &__track {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
      }
      &__slide {
        min-width: 100%;
      }
      &__prev,
      &__next {
        color: var(--btn-blue-color1);
        position: absolute;
        top: calc(50% - 20px);
        cursor: pointer;
        display: block;
        &:hover {
          -webkit-text-stroke-width: 1px;
        }
        svg {
          display: none;
        }
        &::before {
          font-family: "icomoon";
          content: "\e909";
          font-size: 40px;
          display: block;
        }
      }
      &__prev {
        left: -80px;
        &::before {
          transform: rotate(90deg);
        }
      }
      &__next {
        right: -80px;
        &::before {
          transform: rotate(-90deg);
        }
      }
    }
    &--mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 30px;
    }
  }
  &__main {
    margin-bottom: 80px;
    position: relative;
    iframe {
      width: 100%;
      height: 650px;
      border-radius: 15px;
      border: 4px solid var(--btn-blue-color1);
    }
    i {
      position: absolute;
      top: calc(50% - 35px);
      left: calc(50% - 35px);
      font-size: 120px;
      color: var(--white);
      &:hover {
        -webkit-text-stroke-width: 1px;
      }
    }
  }
  &__text {
    color: var(--white);
    position: absolute;
    z-index: 1;
    bottom: 75px;
    left: 75px;
    span {
      font-size: 30px;
      line-height: 45px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
  &__button {
    font-size: 40px;
    color: var(--btn-blue-color1);
    position: absolute;
    top: calc(50% - 20px);
    cursor: pointer;
    &:hover {
      -webkit-text-stroke-width: 1px;
    }
    &--left {
      left: -80px;
      transform: rotate(90deg);
    }
    &--right {
      right: -80px;
      transform: rotate(-90deg);
    }
  }
  @include adaptive(desktop-mid) {
    &__container {
      .carousel {
        &__prev,
        &__next {
          color: var(--white);
        }
        &__prev {
          left: 10px;
        }
        &__next {
          right: 10px;
        }
      }
    }
  }
}
</style>
